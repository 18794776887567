import React from 'react';
import Layout from '../components/common/Layout';

const RootIndex = () => (
  <Layout>
    <h1>Home Page</h1>
  </Layout>
);

export default RootIndex;
