import React from 'react';
import PropTypes from 'prop-types';
import SocialIcon from './SocialIcon';
import SocialIconsFont from '../../fonts/SocialIconsFont';
import {Logo} from '../../images';
import {textToKey} from '../../utils/list';

const Search = () => (
  <form>
    <input />
  </form>
);

const navItems = [
  {text: 'start here', link: '/start-here'},
  {text: 'about', link: '/about'},
  {text: 'blog', link: '/blog'},
  {text: 'resources', link: 'resources'},
];

const Nav = ({navItems}) => (
  <nav>
    <ul>
      {navItems.map(navItem => (
        <li key={textToKey(navItem.text)}>
          <a href={navItem.link}>{navItem.text}</a>
        </li>
      ))}
    </ul>
  </nav>
);

Nav.propTypes = {
  navItems: PropTypes.array,
};

const socialPlatforms = [
  {name: 'twitter', link: '//twitter.com/diyroasters'},
  {name: 'pinterest', link: '//www.pinterest.com/diyroasters'},
  {name: 'instagram', link: '//www.instagram.com/diyroasters'},
  {name: 'facebook', link: '//www.facebook.com/diyroasters'},
  {
    name: 'youtube',
    link: '//www.youtube.com/channel/UCGtBrQpqcG-exPvHN1CcVig',
  },
];

const SocialLinks = () => (
  <ul>
    {socialPlatforms.map(platform => (
      <li key={platform.name}>
        <a href={platform.link}>
          <SocialIcon platform={platform.name} />
        </a>
      </li>
    ))}
  </ul>
);

const Header = () => (
  <header>
    <div>
      <Search />
      <Nav navItems={navItems} />
      <SocialLinks />
    </div>
    <div>
      <Logo />
    </div>
  </header>
);

const policies = [
  {text: 'Privacy Policy', link: '/privacy-policy'},
  {text: 'Affiliate Disclaimer', link: '/affiliate-disclaimer'},
  {text: 'Terms of Use', link: '/terms-of-use'},
  {text: 'DCMA Policy', link: '/dcma-policy'},
  {text: 'Contact us!', link: '/contact-us'},
];

const Footer = () => (
  <footer>
    <span data-testid="thank-you">
      Thank you <Logo /> for visiting!
    </span>
    <ul>
      {policies.map(policy => (
        <li key={textToKey(policy.text)}>
          <a href={policy.link}>{policy.text}</a>
        </li>
      ))}
    </ul>
    <span data-testid="copyright">© Fjellman Managment, LLC</span>
  </footer>
);

const Layout = ({children}) => (
  <React.Fragment>
    <SocialIconsFont />
    <Header />
    {children}
    <Footer />
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
