import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const socialPlatformHexRefs = {
  facebook: '\\e90c',
  google: '\\e900',
  instagram: '\\e906',
  pinterest: '\\e901',
  twitter: '\\e90e',
  youtube: '\\e903',
};

const Icon = styled.i`
  font-family: 'social-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: "${props => props.iconHexRef}";
  }
`;

const SocialIcon = ({platform}) => (
  <Icon iconHexRef={socialPlatformHexRefs[platform]} />
);

SocialIcon.propTypes = {
  platform: PropTypes.string.isRequired,
};

export default SocialIcon;
