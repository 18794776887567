import {createGlobalStyle} from 'styled-components';

import eot from './social-icons.eot?sxj95b';
import eotInternetExplorerFix from './social-icons.eot?sxj95b#iefix';
import ttf from './social-icons.ttf?sxj95b';
import woff from './social-icons.woff?sxj95b';
import svg from './social-icons.svg?sxj95b#social-icons';

export default createGlobalStyle`
  @font-face {
    font-family: 'social-icons';
    src: local('Social Icons'),
         local('SocialIcons'),
         url(${eot}) format('embedded-opentype'),
         url(${eotInternetExplorerFix}) format('embedded-opentype'),
         url(${ttf}) format('truetype'),
         url(${woff}) format('woff'),
         url(${svg}) format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;
